import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders,  HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {GLOBAL} from './global';
import { Observable } from 'rxjs';
import * as cryptojs from 'crypto-js';


@Injectable()
export class ServicioDenunciaService {

  public url: string;

  // public headers = new HttpHeaders().set('http_apikey', '156f38df-24e1-4ee2-8e2a-03f900615a5a');
  public headers = new HttpHeaders({'http_apikey': '156f38df-24e1-4ee2-8e2a-03f900615a5a'});


  constructor(public _http: HttpClient){
    this.url = GLOBAL.url;
  }

  // -------------------------------------------------------------
  // PRUEBAS
  // prueba() {
  //   console.log(this.headers);
  //   // return this._http.get(this.url + '/pruebas', {headers: this.headers});
  //   let data = {
  //     dni:"12345678A",
  //     codigo: "111111aaaaaa",
  //     gasto: 9.12,
  //     categoria: "sorteo"
  //   };
  //   return this._http.post('http://localhost/bonos-navidad/index.php/transferencias/add', data);
  // }

  // -------------------------------------------------------------
  // Login
  login(username, password) {
    let hash = cryptojs.SHA256(`doLogin${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}doLogin`).toString();
    let headers = new HttpHeaders({'hash': hash});

    // return this._http.get(this.url + `/login/check/${username}/${password}`);
    // return this._http.get(this.url + `/login/check/${username}/${password}`, {headers: this.headers});
    return this._http.get(this.url + `/login/check/${username}/${password}`, {headers: headers});
  }

  checkToken(token){
    let hash = cryptojs.SHA256(`tokenCheck${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}tokenCheck`).toString();
    let headers = new HttpHeaders({'hash': hash});
    
    return this._http.get(this.url + `/login/checkToken/${token}`, {headers: headers});
  }


  // -------------------------------------------------------------
  // Denuncias
  addDenuncia(denuncia) {
    let hash = cryptojs.SHA256(`denunciaAdd${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}denunciaAdd`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.post(this.url + '/denuncias/add', denuncia, {headers: headers});
  }

  updateDenuncia(denuncia){
    let hash = cryptojs.SHA256(`denunciaUpdate${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}denunciaUpdate`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.post(this.url + '/denuncias/update', denuncia, {headers: headers});
  }

  updateDenunciaCE(denuncia){
    let hash = cryptojs.SHA256(`denunciaUpdateCE${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}denunciaUpdateCE`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.post(this.url + '/denuncias/updateCE', denuncia, {headers: headers});
  }

  getDenuncias() {
    let hash = cryptojs.SHA256(`denunciaGet${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}denunciaGet`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + '/denuncias/search', {headers: headers});
  }

  getDenunciaById(id) {
    let hash = cryptojs.SHA256(`denunciaGetByID${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}denunciaGetByID`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + '/denuncias/search/' + id, {headers: headers});
  }

  getDenunciaByCodigo(codigo) {
    let hash = cryptojs.SHA256(`denunciaGetByCodigo${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}denunciaGetByCodigo`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + '/denuncias/searchCodigo/' + codigo, {headers: headers});
  }

  // ---------------------------------------------------------------
  // Implicados
  addImplicado(implicado) {
    let hash = cryptojs.SHA256(`implciadoAdd${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}implciadoAdd`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.post(this.url + '/implicados/add', implicado, {headers: headers});
  }

  getImplicado(id){
    let hash = cryptojs.SHA256(`implicadoGet${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}implicadoGet`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + '/implicados/search/' + id, {headers: headers});
  }

  // ----------------------------------------------------------------
  // Testigos
  addTestigo(testigo) {
    let hash = cryptojs.SHA256(`testigoAdd${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}testigoAdd`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.post(this.url + '/testigos/add', testigo, {headers: headers});
  }

  getTestigo(id){
    let hash = cryptojs.SHA256(`testigoGet${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}testigoGet`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + '/testigos/search/' + id, {headers: headers});
  }

  // --------------------------------------------------------------
  // Detalles extra
  addDetalle(detalle) {
    let hash = cryptojs.SHA256(`detalleAdd${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}detalleAdd`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.post(this.url + '/detalles/add', detalle, {headers: headers});
  }

  getDetalles(id_denuncia){
    let hash = cryptojs.SHA256(`detalleGet${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}detalleGet`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + `/detalles/search/${id_denuncia}`, {headers: headers});
  }

  // --------------------------------------------------------------
  // Notas
  addNota(nota) {
    let hash = cryptojs.SHA256(`notaAdd${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}notaAdd`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.post(this.url + '/notas/add', nota, {headers: headers});
  }

  getNotas(id_denuncia){
    let hash = cryptojs.SHA256(`notaGet${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}notaGet`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + `/notas/search/${id_denuncia}`, {headers: headers});
  }

  // --------------------------------------------------------------
  // Archivos
  makeFileRequest(File): Observable<any>{
    const fd = new FormData();
    for(let i=0; i<File.length; i++){
        fd.append('uploads1[]', File[i], File[i].name);
    }

    let hash = cryptojs.SHA256(`fileRequest${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}fileRequest`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.post(this.url + '/owned/images', fd, {headers: headers});
  }

  addArchivo(archivo) {
    let hash = cryptojs.SHA256(`archivoAdd${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}archivoAdd`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.post(this.url + '/archivos/add', archivo, {headers: headers});
  }

  getArchivos(){
    let hash = cryptojs.SHA256(`archivoGet${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}archivoGet`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + '/archivos/search', {headers: headers});
  }

  getArchivosById(id){
    let hash = cryptojs.SHA256(`archivoGetById${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}archivoGetById`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + `/archivos/search/${id}`, {headers: headers});
  }

  getArchivosResolucionById(id){
    let hash = cryptojs.SHA256(`archivoGetResolucionById${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}archivoGetResolucionById`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + `/archivos/searchResolucion/${id}`, {headers: headers});
  }

  // --------------------------------------------------------------
  // Actualizaciones
  addActualizacion(actualizacion){
    let hash = cryptojs.SHA256(`actualizacionAdd${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}actualizacionAdd`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.post(this.url + '/actualizaciones/add', actualizacion, {headers: headers});
  }

  getActualizaciones() {
    let hash = cryptojs.SHA256(`actualizacionGet${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}actualizacionGet`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + '/actualizaciones/search', {headers: headers});
  }

  // --------------------------------------------------------------
  // Relaciones
  getRelaciones() {
    let hash = cryptojs.SHA256(`relacionGet${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}relacionGet`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + '/relacion/search', {headers: headers});
  }

  // --------------------------------------------------------------
  // Categoria
  getCategoria() {
    let hash = cryptojs.SHA256(`categoriaGet${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}categoriaGet`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + '/categoria/search', {headers: headers});
  }

  // --------------------------------------------------------------
  // Categoria
  getPosiblesImplicados() {
    let hash = cryptojs.SHA256(`posibleimplicadoGet${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}posibleimplicadoGet`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.get(this.url + '/posibleimplicado/search', {headers: headers});
  }


  // --------------------------------------------------------------
  // Email
  sendEmail(id) {
    let hash = cryptojs.SHA256(`emailSend${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}emailSend`).toString();
    let headers = new HttpHeaders({'hash': hash});

    return this._http.post(this.url + '/email/nuevaDenuncia', {id: id}, {headers: headers});
  }

}
