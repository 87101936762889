<div id="containerLogin1">

    <div id="izqLogin">
        <div class="text-center">
            <h2>Política de protección de datos y consentimiento del Canal de Denuncias del Colegio Oficial de Veterinarios del Principado de Asturias</h2>
            <hr>
            <div class="text-left">
                <p>
                    Para poder recibir y tramitar tu denuncia, debes confirmar que has leído este Aviso de Privacidad. En caso de que no aceptes las condiciones conforme a lo dispuesto en el aviso, no podrás realizar la denuncia.
                </p>
                <p>
                    En este sentido y en cumplimiento de lo dispuesto en el artículo 13 del Reglamento (UE) 2016/679 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (Reglamento
                    General de Protección de Datos o RGPD) y artículo 11 de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPDyGDD) a continuación, se expone la información detallada
                    sobre el tratamiento de datos de carácter personal en el marco del Canal de Denuncias del Colegio Oficial de Veterinarios del Principado de Asturias.
                </p>
                <p>
                    Este canal de denuncias está gestionado por el Colegio Oficial de Veterinarios del Principado de Asturias, con CIF Q-3371001-C, y domicilio social en C/Marqués de Pidal, 6, 2ºC, 33004, Oviedo, Asturias, España.
                </p>
                <p>
                    El Colegio Oficial de Veterinarios del Principado de Asturias garantiza la seguridad y la confidencialidad de los datos recopilados para la finalidad del tratamiento, de conformidad con el presente Aviso de Privacidad.
                </p>
            </div>
        </div>
    </div>
</div>





<div id="containerLogin2">
    <br>
    <div class="text-left">
        <p>A continuación, te mostramos la información básica sobre como tratamos tus datos personales:</p>
        <br>
        <table class="table">
            <tbody>
                <tr>
                    <td>
                        <strong>Responsable de tratamiento</strong>
                    </td>
                    <td>
                        Colegio Oficial de Veterinarios del Principado de Asturias, con CIF Q-3371001-C, y domicilio social en C/Marqués de Pidal, 6, 2ºC, 33004, Oviedo, Asturias, España.

                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Delegado de protección de datos</strong>
                    </td>
                    <td>
                        Puedes contactar a través del correo: <a href="mailto:asesoriajuridica@colegioveterinarios.net">asesoriajuridica@colegioveterinarios.net</a>.
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Datos objeto de tratamiento y procedencia</strong>
                    </td>
                    <td>
                        <p>- Datos de contacto, si los proporcionas (nombre, apellidos, correo electrónico y teléfono). En todo caso, al comunicación podrá presentarse de forma anónima.</p>
                        <p>- Nombre y cargo de los personas sobre las que informe en la comunicación, ya sea como implicados o como testigos.</p>
                        <p>- Información sobre los hechos denunciados que pueda contener datos de carácter personal. </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Finalidad del tratamiento y Legitimación</strong>
                    </td>
                    <td>
                        <p>
                            De conformidad con la normativa vigente en materia de protección de datos, el Colegio Oficial de Veterinarios del Principado de Asturias te informa de que tus datos personales serán recabados y tratados para la adecuada gestión de nuestro sistema interno
                            de información, tramitando las correspondientes irregularidades notificadas a través de Canal de Denuncias, y decidiendo sobre la procedencia de iniciar una investigación, al objeto de detectar posibles infracciones, así como
                            para evitar cualquier tipo de conducta contraria a la normativa.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Legitimación del tratamiento</strong>
                    </td>
                    <td>
                        <p>
                            El tratamiento de tus datos encuentra su legitimación en la obligación legal establecida en los artículos 10 y 13 de la Ley 2/2023, de 20 de febrero, reguladora de la protección de las personas que informen sobre infracciones normativas y de lucha contra
                            la corrupción, de disponer de estos sistemas internos de información (artículo 6.1.c) RGPD)
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Reserva de identidad del </strong>
                    </td>
                    <td>
                        <p>
                            Conforme al artículo 31.1 de la Ley 2/2023, le informamos de que su identidad será en todo caso reservada y que no se comunicará a las personas a las que se refieren los hechos relatados ni a terceros.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Destinatarios</strong>
                    </td>
                    <td>
                        <p>
                            Salvo obligación legal, solo se comunicarán sus datos a las siguientes categorías de destinatarios:
                        </p>
                        <ul>
                            <li>
                                Juzgados y Tribunales, así como otros posibles órganos de resolución de conflictos.
                            </li>
                            <li>
                                Fuerzas y Cuerpos de Seguridad del Estado.
                            </li>
                        </ul>
                        <p>
                            Notarios y registradores.
                        </p>
                        <p>
                            Con los proveedores que precisen acceder a sus datos personales para la prestación de los servicios que les hayamos contratado o que por el propio funcionamiento de nuestros servicios electrónicos (página web y correos electrónicos) puedan tener acceso
                            a determinados datos personales, tenemos suscritos los contratos de confidencialidad y de encargo de tratamiento de datos personales necesarios y exigidos por la normativa para proteger su privacidad (artículo 28.3 RGPD).
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Criterios de Conservación, Gestión y Custodia</strong>
                    </td>
                    <td>
                        <p>
                            Conservaremos sus datos durante un plazo máximo de tres meses tras la notificación de la irregularidad, si los hechos no hubieran sido probados, y siempre que no resulten necesarios para otras finalidades o a efectos probatorios del debido control y supervisión
                            en la prevención de delitos.
                        </p>
                        <p>
                            En caso de que los hechos sí resulten probados o con indicios suficientes, los datos se conservarán en tanto sea necesario para el ejercicio por parte de la entidad de sus derechos ante los tribunales de justicia, y cuando ya no sea necesario para ello,
                            se suprimirán con medidas de seguridad adecuadas para garantizar la anonimización de los datos o la destrucción total de los mismos.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Derechos de los interesados</strong>
                    </td>
                    <td>
                        <p>
                            Usted tiene derecho a obtener confirmación sobre el tratamiento de sus datos de carácter personal por parte de esta entidad. En concreto, usted tiene los siguientes derechos:
                        </p>
                        <ul>
                            <li>
                                Derechos de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento.
                            </li>
                            <li>
                                Derecho a presentar una reclamación ante la Autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente.
                            </li>
                        </ul>
                        <p>
                            Datos de contacto para ejercer sus derechos: Colegio Oficial de Veterinarios de Asturias. Marqués de Pidal,, 6 2º c - 33004 OVIEDO (Asturias). E-mail: <a href="asesoriajuridica@colegioveterinarios.net">asesoriajuridica@colegioveterinarios.net</a>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Cookies</strong>
                    </td>
                    <td>
                        El Canal de Denuncias del Colegio Oficial de Veterinarios del Principado de Asturias no emplea cookies para recoger información de las personas usuarias. Únicamente se utilizan cookies propias, de sesión, con finalidad técnica (aquellas que permiten a
                        la persona usuaria la navegación a través de la plataforma digital y la utilización de las diferentes opciones y servicios que en ella existen) y de registro. </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div style="height: 150px;"></div>
</div>